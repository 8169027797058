/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import { EventType } from 'src/dto/pmo/eventtype';
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI, Attachment, EventAttachment
} from '../dto';

import { Event_v1 } from './event-v1';
import { PublicationType } from './publicationtype';

export interface Task_v1 extends Event_v1, DTOMethods<Event_v1> {
    eventType: number;
    startDate: Date;
    endDate: Date;
    isCriticalPath: boolean;
    baselineStart: Date;
    baselineEnd: Date;
    createdDate: Date;
    completedDate: Date;
    hardEnd: Date;
    scheduledHours: number;
    scheduledHoursManual: number;
    progress: number;
    progressManual: number;
    progressOverride: boolean;
    status: string;
    priority: number;
    rank: number;
    duration: number;
    durationType: string;
    durationUnit: string;
    startTimezone: string;
    endTimezone: string;
    isReadOnly: boolean;
    isPublished: boolean;
    publication?: EventAttachment;
    children: DTORef[];
    files: EventAttachment[];
    dependencies: Attachment[];
    responses: Attachment[];
    resolvedUsers: Attachment[];
    resolvedAudience: DTORef[];
}
export const Task_v1: DTOMetaData & DTOClassMethods<Task_v1> = {
    _label:'Tasks',
    _dto: 'Task_v1',
    _type: 'task',
    _colors: { primaryThemeColor: "#6d9b44", secondaryThemeColor: "#558a1f", tertiaryThemeColor: "#558a1f" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'Event type', prop: 'eventType', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: EventType.TASK },
        { label: 'Start date', prop: 'startDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'End date', prop: 'endDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Is critical path', prop: 'isCriticalPath', hint: 'Yes/No', type: 'boolean', fixed: true, source: undefined, values: undefined, default: false },
        { label: 'Baseline start', prop: 'baselineStart', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Baseline end', prop: 'baselineEnd', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Created date', prop: 'createdDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Completed date', prop: 'completedDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Hard end', prop: 'hardEnd', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Scheduled hours', prop: 'scheduledHours', hint: 'Decimal value (7 decimals)', type: 'number', fixed: true, source: undefined, values: undefined, default: 8 },
        { label: 'Scheduled hours manual', prop: 'scheduledHoursManual', hint: 'Decimal value (7 decimals)', type: 'number', fixed: true, source: undefined, values: undefined, default: 8 },
        { label: 'Progress', prop: 'progress', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Progress manual', prop: 'progressManual', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Progress override', prop: 'progressOverride', hint: 'Yes/No', type: 'boolean', fixed: true, source: undefined, values: undefined, default: false },
        { label: 'Status', prop: 'status', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "New" },
        { label: 'Priority', prop: 'priority', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Rank', prop: 'rank', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Rag', prop: 'rag', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Duration', prop: 'duration', hint: 'Decimal value (7 decimals)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Duration type', prop: 'durationType', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Duration unit', prop: 'durationUnit', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Start timezone', prop: 'startTimezone', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'End timezone', prop: 'endTimezone', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Is read only', prop: 'isReadOnly', hint: 'Yes/No', type: 'boolean', fixed: true, source: undefined, values: undefined, default: false },
        { label: 'Is published', prop: 'isPublished', hint: 'Yes/No', type: 'boolean', fixed: true, source: undefined, values: undefined, default: false },
        { label: 'Publication type', prop: 'publicationType', hint: 'Publication type', type: 'enum', fixed: false, source: undefined, values: ["NEWS", "BLOG", "QUESTIONNAIRE"], default: PublicationType.NEWS },
        { label: 'Publication start', prop: 'publicationStart', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Publication end', prop: 'publicationEnd', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Publication author', prop: 'publicationAuthor', hint: 'User', type: 'dto', fixed: true, source: '/', values: undefined, default: undefined },
        { label: 'Attached publication', prop: 'attachedPublication', hint: 'Attachment', type: 'dto', fixed: false, source: undefined, values: undefined, default: undefined },
     ],
    _related: [
        { prop: 'children', type: 'task', class: 'Task_v1', label: 'Subtasks' },
        { prop: 'dependencies', type: 'unknown', class: 'Unknown', label: 'Dependencies' },
        { prop: 'responses', type: 'unknown', class: 'Unknown', label: 'Responses' }
    ],
    _endpoint: '/api/eda/v2.0/task/v1.0',
    _depth: 3,
    _docLink: '/wiki/elevate/Assets/task/',
    _parentDTONames: 'Objective_v1,CoverageObjective_v1',
    _childrenAt: 'children',
    _icon: './assets/dtos/task.svg',
    _rootDTOName: 'Project_v1',
    endpointAPI: new BoundEndpointAPI<Task_v1>('Task_v1', DTO),
    from: (obj: any): Task_v1 => {
        return Object.setPrototypeOf(obj, Task_v1._prototype);
    },
    select: (p: any = ''): any => {
        return Task_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => Task_v1.from(a) as Task_v1)
            : Task_v1.from(res)
    )}
}
